import {
  Spacer,
  VStack,
  Heading,
  Spinner,
  Text,
  Flex,
  Icon,
  FormControl,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Input,
  Checkbox,
} from '@chakra-ui/react'
import EnforcementUserInput, {
  EMAIL_BODY,
  SUBMISSION_EMAIL,
} from '@/components/enforcements/enforcement_user_input'
import { EnforcementTemplateRow, TemplateSection } from './enforcement_template'
import {
  ENFORCEMENT_TYPE,
  SocialMediaPlatform,
  DomainPlatform,
  DOMAIN_PLATFORM,
  EntityType,
} from '@/utils/constants'
import { useGetSpoofTemplatesLazyQuery } from '@/generated/graphql'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { WarningIcon, ChevronDownIcon } from '@chakra-ui/icons'
import DoppelFormLabel from '@/components/shared/forms/doppel_form_label'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import { DOPPEL_TEXT_RED, MENU_LIST_ZINDEX } from '@/utils/style'
import {
  getEmailTemplateWithAdditionalNotes,
  isAutoEnforcementStagable,
  renderFlaggedUrl,
} from '@/utils/enforcement_utils'
import { ProductType } from '@/utils/constants'
import {
  AppEnforcementPlatformType,
  EnforcementEmailStage,
  EnforcementMethod,
  PlatformName,
} from '@/generated/enums'
import useTemplateInfoInputs, {
  useFetchTemplateInput,
} from '@/components/enforcements/populate_template_helper'

const TRADEMARK = '[TRADEMARK]'
const ORG_CONFIG = '[ORG CONFIG]'
const BRAND_CONFIG = '[BRAND_CONFIG]'
const DEFANG_ENFORCEMENT_METHODS = [
  EnforcementMethod.AUTO_EMAIL,
  EnforcementMethod.MANUAL_EMAIL,
]
const PERSONAL_BRAND_PLATFORMS_REQUIRE_ASSET = [
  PlatformName.FACEBOOK,
  PlatformName.TWITTER,
  PlatformName.INSTAGRAM,
  PlatformName.PINTEREST,
  PlatformName.LINKEDIN,
]

// React component that renders the automated takedown section of the internal enforcement form
export default function AutomatedTakedownSection({
  selectedEnforcementPlatform,
  enforcementPlatform,
  selectedEnforcementType,
  selectedEnforcementMethodType,
  enforcementUserInput,
  setEnforcementUserInput,
  setReasonsStagingDisabled,
  isAutoEmailEnforcementEnabled,
  submissionEmail,
  setSubmissionEmail,
  setTemplateInfo = null,
  setAdditionalTemplateInfo = null,
  trademarkId = null,
  customPlatform = null,
  platformType = null,
  linkedAlertData = null,
}) {
  const { register: trademarkRegister, watch: trademarkWatch } = useForm()
  const [trademarkData, setTrademarkData] = useState({})
  const [moreInfoInputs, setMoreInfoInputs] = useState(null)
  const [missingUserInputFields, setMissingUserInputFields] = useState([])
  const [missingConfigFields, setMissingConfigFields] = useState([])
  const [selectedSpoofReport, _] = useSelectedSpoofReport()
  const [selectedTrademarkId, setSelectedTrademarkId] = useState(trademarkId)
  const [flaggedSlug, setFlaggedSlug] = useState(null)
  const [templateBody, setTemplateBody] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [enforcementTemplate, setEnforcementTemplate] = useState(null)
  const [fetchTemplateQuery] = useGetSpoofTemplatesLazyQuery()
  const [shouldDefangUrls, setShouldDefangUrls] = useState(
    DEFANG_ENFORCEMENT_METHODS.includes(selectedEnforcementMethodType),
  )
  const { templateInput, loading: templateInputLoading } = useFetchTemplateInput(
    selectedSpoofReport,
    selectedEnforcementPlatform,
  )
  const [userAcknowledgedMissingAsset, setUserAcknowledgedMissingAsset] =
    useState(false)
  const [isUsingOrgLevelDesignatedAssets, setIsUsingOrgLevelDesignatedAssets] =
    useState(false)
  const [shouldShowMissingAssetWarning, setShouldShowMissingAssetWarning] =
    useState(false)

  useEffect(() => {
    // If brand designated assets len = 0 or is none and org designated assets len > 0
    setIsUsingOrgLevelDesignatedAssets(
      !moreInfoInputs?.brand_protected_assets?.length &&
        !!moreInfoInputs?.organization_protected_assets?.length,
    )
  }, [
    moreInfoInputs?.brand_protected_assets,
    moreInfoInputs?.organization_protected_assets,
  ])

  useEffect(() => {
    setShouldShowMissingAssetWarning(
      selectedSpoofReport?.spoofing_entity?.entity_type === EntityType.PERSONAL_BRAND &&
        PERSONAL_BRAND_PLATFORMS_REQUIRE_ASSET.includes(selectedEnforcementPlatform) &&
        !moreInfoInputs?.brand_protected_assets?.length &&
        !moreInfoInputs?.organization_protected_assets?.length,
    )
  }, [
    selectedSpoofReport?.spoofing_entity?.entity_type,
    moreInfoInputs?.brand_protected_assets,
    moreInfoInputs?.organization_protected_assets,
    selectedEnforcementPlatform,
  ])

  useEffect(() => {
    if (enforcementTemplate?.template_body && emailBody) {
      setTemplateBody(
        getEmailTemplateWithAdditionalNotes(
          emailBody,
          enforcementTemplate?.template_body,
        ),
      )
    } else {
      setTemplateBody(enforcementTemplate?.template_body)
    }
  }, [enforcementTemplate, emailBody])

  useEffect(() => {
    setShouldDefangUrls(
      DEFANG_ENFORCEMENT_METHODS.includes(selectedEnforcementMethodType),
    )
  }, [selectedEnforcementMethodType])

  useEffect(() => {
    if (enforcementTemplate && moreInfoInputs && setTemplateInfo) {
      setTemplateInfo({
        unfilledTemplate: enforcementTemplate.template_body,
        templateFields: moreInfoInputs,
      })
    }
  }, [moreInfoInputs, enforcementTemplate])

  const getPlatformForEnforcementTemplateQuery = () => {
    return (
      (selectedSpoofReport?.platform_subtype?.product === ProductType.DOMAIN &&
        DOMAIN_PLATFORM) ||
      (platformType &&
        platformType === AppEnforcementPlatformType.THIRD_PARTY_APK &&
        AppEnforcementPlatformType.THIRD_PARTY_APK) ||
      ''
    )
  }

  const getEnforcementTemplateForPlatform = async () => {
    const templates = await fetchTemplateQuery({
      variables: {
        platform: enforcementPlatform,
        stage: EnforcementEmailStage.INITIAL,
        type: selectedEnforcementType,
      },
    })

    if (templates?.data?.enforcement_templates[0]) {
      return templates?.data?.enforcement_templates[0]
    }

    const productTemplates = await fetchTemplateQuery({
      variables: {
        platform: getPlatformForEnforcementTemplateQuery(),
        stage: EnforcementEmailStage.INITIAL,
        type: selectedEnforcementType,
      },
    })

    return productTemplates?.data?.enforcement_templates[0]
  }

  useEffect(() => {
    setMissingConfigFields(
      Object.entries(
        getRequiredConfigFields(
          isAutoEmailEnforcementEnabled,
          enforcementPlatform,
          selectedEnforcementType,
          moreInfoInputs,
          submissionEmail,
          selectedSpoofReport?.platform_subtype?.product,
        ),
      )
        .filter(([_, value]) => {
          if (typeof value === 'string') {
            return value.trim() === ''
          }
          return !value
        })
        .map(([key, _]) => key),
    )
  }, [
    enforcementPlatform,
    selectedEnforcementPlatform,
    selectedEnforcementType,
    moreInfoInputs,
    moreInfoInputs?.brand_protected_assets,
    moreInfoInputs?.organization_protected_assets,
  ])

  useEffect(() => {
    const missingConfigFieldsString = missingConfigFields?.map(
      (field) => `Missing config field: ${field}`,
    )
    const missingUserInputFieldsString = missingUserInputFields?.map(
      (field) => `Missing user input field: ${field}`,
    )
    const missingDesignatedAssetString =
      !userAcknowledgedMissingAsset && shouldShowMissingAssetWarning
        ? [
            'Please check the box above and validate that the missing designated asset is correct.',
          ]
        : []
    const usingOrgLevelDesignatedAssetString =
      !userAcknowledgedMissingAsset && isUsingOrgLevelDesignatedAssets
        ? [
            'Please check the box above to validate the usage of the organization-level designated asset.',
          ]
        : []
    const enforcementRequestSubmitted = isAutoEnforcementStagable(
      selectedSpoofReport,
      enforcementPlatform,
      selectedEnforcementType,
    )
      ? []
      : ['Enforcement request already submitted']
    setReasonsStagingDisabled(
      missingConfigFieldsString.concat(
        missingUserInputFieldsString,
        enforcementRequestSubmitted,
        missingDesignatedAssetString,
        usingOrgLevelDesignatedAssetString,
      ),
    )
  }, [
    missingUserInputFields,
    missingConfigFields,
    selectedSpoofReport,
    selectedEnforcementPlatform,
    enforcementPlatform,
    selectedEnforcementType,
    userAcknowledgedMissingAsset,
    shouldShowMissingAssetWarning,
    isUsingOrgLevelDesignatedAssets,
  ])

  useTemplateInfoInputs({
    templateInput,
    setTemplateInfoInputs: setMoreInfoInputs,
    selectedEnforcementPlatform,
    submissionEmail,
    shouldDefangUrls,
    selectedSpoofReport,
    linkedAlertData,
  })

  useEffect(() => {
    const trademarkData = templateInput?.spoofing_entities[0]?.trademarks

    let enforcementMetadata =
      templateInput?.spoofing_entities[0]?.organization?.enforcement_metadata
    if (enforcementMetadata && '__typename' in enforcementMetadata) {
      const { ['__typename']: _, ...sanitizedMetadata } = enforcementMetadata
      enforcementMetadata = sanitizedMetadata
    }

    setTrademarkData(
      trademarkData?.reduce((acc, trademark) => {
        let sanitizedTrademark = trademark
        if (trademark && '__typename' in trademark) {
          const { ['__typename']: _, ...rest } = trademark
          sanitizedTrademark = rest
        }
        acc[trademark.id] = sanitizedTrademark
        return acc
      }, {}),
    )
    const setUserInputs = async () => {
      const firstTemplateData = await getEnforcementTemplateForPlatform()
      setEnforcementUserInput((prevState) => ({
        ...prevState,
        [EMAIL_BODY]: firstTemplateData?.template_body,
      }))
      setEnforcementTemplate(firstTemplateData)
    }
    setUserInputs()
  }, [templateInput?.spoofing_entities, selectedSpoofReport, submissionEmail])

  useEffect(() => {
    if (trademarkData && trademarkId) {
      setMoreInfoInputs((prevState) => ({
        ...prevState,
        ...trademarkData[trademarkId],
      }))
    }
  }, [trademarkData, trademarkId])

  const renderManualFlaggedSlug = () => {
    if (
      selectedSpoofReport?.platform_subtype?.product !== ProductType.SOCIAL_MEDIA ||
      selectedSpoofReport?.spoof_matches?.[0]?.social_media_data?.slug ||
      selectedSpoofReport?.social_media_data?.slug
    ) {
      return <></>
    }

    return (
      <FormControl isRequired>
        <VStack align="left">
          <DoppelFormLabel
            formLabel="Flagged Slug"
            helperText="Enter the slug of the account that is being reported"
          />

          <Input
            onChange={(e) => {
              setFlaggedSlug(e.target.value)
              setMoreInfoInputs((prevState) => ({
                ...prevState,
                slug: e.target.value,
              }))
              setEnforcementUserInput((prevState) => ({
                ...prevState,
                slug: e.target.value,
              }))
            }}
            value={flaggedSlug}
          />
        </VStack>
      </FormControl>
    )
  }

  const renderSubmissionEmail = () => {
    if (!isAutoEmailEnforcementEnabled) {
      return <></>
    }

    return (
      <FormControl isRequired>
        <VStack align="left">
          <DoppelFormLabel
            formLabel="Submission Email"
            helperText="Enter the email to send the enforcement request to"
          />

          {customPlatform && (
            <Heading as="h4" fontSize={15} size="sm">
              Platform {customPlatform} not found in our system. Please enter the email
              to send the enforcement request to.
            </Heading>
          )}

          <Input
            onChange={(e) => {
              setSubmissionEmail(e.target.value)
              setMoreInfoInputs((prevState) => ({
                ...prevState,
                [SUBMISSION_EMAIL]: e.target.value,
              }))
              setEnforcementUserInput((prevState) => ({
                ...prevState,
                [SUBMISSION_EMAIL]: e.target.value,
              }))
            }}
            value={submissionEmail}
          />
        </VStack>
      </FormControl>
    )
  }

  const renderAdditionalNotes = () => {
    if (!isAutoEmailEnforcementEnabled) {
      return <></>
    }

    return (
      <FormControl>
        <VStack align="left">
          <DoppelFormLabel
            formLabel="Additional Notes"
            helperText="Enter any additional information you want to add to the end of the email"
          />

          <Input
            onChange={(e) => {
              setEmailBody(e.target.value)
              setEnforcementUserInput((prevState) => ({
                ...prevState,
                [EMAIL_BODY]: getEmailTemplateWithAdditionalNotes(
                  e.target.value,
                  enforcementTemplate?.template_body,
                ),
              }))
              if (setAdditionalTemplateInfo) {
                setAdditionalTemplateInfo(e.target.value)
              }
            }}
            value={emailBody}
          />
        </VStack>
      </FormControl>
    )
  }

  const renderTrademarkDropdown = () => {
    const trademarkOptions = trademarkData ? Object.keys(trademarkData) : []
    return (
      selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK && (
        <FormControl isRequired>
          <VStack align="left">
            <DoppelFormLabel
              formLabel="Trademark"
              helperText="Select the trademark to enforce"
            />

            <Menu>
              <MenuButton as={Button} maxWidth="20%" mb="4">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text fontSize="13" minWidth="100px">
                    {selectedTrademarkId ? trademarkData[selectedTrademarkId].mark : ''}
                  </Text>

                  <ChevronDownIcon />
                </Flex>
              </MenuButton>

              <MenuList maxHeight="280px" overflow="scroll" zIndex={MENU_LIST_ZINDEX}>
                {trademarkOptions.map((id) => (
                  <MenuItem
                    fontSize="13"
                    key={id}
                    onClick={() => {
                      setSelectedTrademarkId(id)
                      setMoreInfoInputs((prevState) => ({
                        ...prevState,
                        ...trademarkData[id],
                      }))
                      setEnforcementUserInput((prevState) => ({
                        ...prevState,
                        trademark_id: id,
                      }))
                    }}
                    value={id}
                  >
                    {trademarkData[id].mark}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </VStack>
        </FormControl>
      )
    )
  }

  const renderTrademarkInformation = () => {
    return (
      selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK &&
      selectedTrademarkId &&
      trademarkData &&
      Object.keys(trademarkData).length > 0 &&
      trademarkData[selectedTrademarkId] && (
        <>
          <Spacer />

          <DoppelFormLabel formLabel="Trademark Holder's Information" />

          {Object.keys(trademarkData[selectedTrademarkId]).length > 0 ? (
            Object.entries(trademarkData[selectedTrademarkId]).map(([key, value]) => (
              <EnforcementTemplateRow
                dbValue={value}
                formRegister={trademarkRegister}
                formWatch={trademarkWatch}
                key={key}
                label={key}
              />
            ))
          ) : (
            <Flex alignItems="center">
              <Icon as={WarningIcon} color={'red.500'} fontSize={12} />

              <Text paddingLeft="2">No trademark object added yet</Text>
            </Flex>
          )}
        </>
      )
    )
  }

  const renderTemplate = () => {
    function handleCheckboxChange(e) {
      setShouldDefangUrls(e.target.checked)
    }
    return (
      <VStack align={'left'}>
        <Checkbox isChecked={shouldDefangUrls} onChange={handleCheckboxChange}>
          Defang URLs
        </Checkbox>

        <TemplateSection
          label={'Template'}
          moreInfoInputs={moreInfoInputs}
          template={templateBody}
        />
      </VStack>
    )
  }

  const renderMissingDesignatedAssetWarning = () => {
    let formLabel = ''
    let helperText = ''
    let checkboxText = ''
    if (shouldShowMissingAssetWarning) {
      formLabel = `No protected asset found for brand: ${selectedSpoofReport.spoofing_entity?.entity_name}`
      helperText = `No protected asset found for ${selectedEnforcementPlatform}, please confirm asset not required to submit this takedown request for the brand: ${selectedSpoofReport.spoofing_entity?.entity_name}`
      checkboxText =
        'I acknowledge that no protected asset is required to submit this takedown request'
    } else if (isUsingOrgLevelDesignatedAssets) {
      formLabel = `Using org default asset ${moreInfoInputs?.entity_url} since no asset found for brand: ${selectedSpoofReport.spoofing_entity?.entity_name}`
      helperText = `No protected asset found for ${selectedEnforcementPlatform}, please confirm the org level default (${moreInfoInputs?.entity_url}) is suitable for this takedown request for the brand: ${selectedSpoofReport.spoofing_entity?.entity_name}`
      checkboxText = `I acknowledge that the org default asset, ${moreInfoInputs?.entity_url}, is appropriate for this takedown request`
    }

    return (
      <FormControl isRequired>
        <DoppelFormLabel
          formLabel={formLabel}
          helperText={helperText}
          textColor={DOPPEL_TEXT_RED}
        />

        <Checkbox
          isChecked={userAcknowledgedMissingAsset}
          isRequired
          onChange={(e) => setUserAcknowledgedMissingAsset(e.target.checked)}
        >
          <Text>{checkboxText}</Text>
        </Checkbox>
      </FormControl>
    )
  }

  if (templateInputLoading) {
    return <Spinner />
  }
  if (!templateInputLoading && !templateInput) {
    return <Heading>Something went wrong</Heading>
  }

  return (
    selectedEnforcementType &&
    selectedEnforcementPlatform && (
      <VStack alignItems="flex-start" padding="12" spacing={4} width="100%">
        <Spacer />

        <Heading as="h3" fontSize={20} size="sm">
          Auto Enforcement Details
        </Heading>

        <Spacer />

        {renderTemplate()}

        {renderFlaggedUrl(selectedSpoofReport?.flagged_url)}

        {renderManualFlaggedSlug()}

        {renderSubmissionEmail()}

        {renderAdditionalNotes()}

        {!trademarkId && renderTrademarkDropdown()}

        {renderTrademarkInformation()}

        <Spacer />

        <EnforcementUserInput
          enforcementUserInput={enforcementUserInput}
          protectedAssets={
            moreInfoInputs?.brand_protected_assets?.length > 0
              ? moreInfoInputs?.brand_protected_assets
              : moreInfoInputs?.organization_protected_assets
          }
          selectedEnforcementPlatform={enforcementPlatform}
          selectedEnforcementType={selectedEnforcementType}
          setEnforcementUserInput={setEnforcementUserInput}
          setMissingUserInputFields={setMissingUserInputFields}
          setMoreInfoInputs={setMoreInfoInputs}
        />

        <Spacer />

        {(shouldShowMissingAssetWarning || isUsingOrgLevelDesignatedAssets) &&
          renderMissingDesignatedAssetWarning()}
      </VStack>
    )
  )
}

const getRequiredConfigFields = (
  isAutoEmailEnforcementEnabled,
  platform,
  type,
  moreInfoInputs,
  submissionEmail,
  productType,
) => {
  let requiredConfigFields = {}
  if (
    platform === SocialMediaPlatform.TWITTER.toLowerCase() &&
    type === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()
  ) {
    requiredConfigFields = {
      slug: moreInfoInputs?.slug,
      [`${TRADEMARK} holder_name`]: moreInfoInputs?.holder_name,
      [`${TRADEMARK} holder_address`]: moreInfoInputs?.holder_address,
      [`${TRADEMARK} holder_country_code`]: moreInfoInputs?.holder_country_code,
      [`${TRADEMARK} holder_website`]: moreInfoInputs?.holder_website,
      [`${TRADEMARK} holder_company_name`]: moreInfoInputs?.holder_company_name,
      [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
      [`${TRADEMARK} registration_number`]: moreInfoInputs?.registration_number,
      [`${TRADEMARK} goods_or_services_class`]: moreInfoInputs?.goods_or_services_class,
      [`${TRADEMARK} registration_office`]: moreInfoInputs?.registration_office,
      [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
      [`${ORG_CONFIG} authorization_letter_url`]:
        moreInfoInputs?.authorization_letter_url,
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
    }
  } else if (
    type === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase() &&
    platform === SocialMediaPlatform.TIKTOK.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${TRADEMARK} holder_name`]: moreInfoInputs?.holder_name,
      [`${TRADEMARK} holder_address`]: moreInfoInputs?.holder_address,
      [`${TRADEMARK} holder_country_code`]: moreInfoInputs?.holder_country_code,
      [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
      [`${TRADEMARK} registration_number`]: moreInfoInputs?.registration_number,
      [`${TRADEMARK} goods_or_services_class`]: moreInfoInputs?.goods_or_services_class,
      [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
      [`${ORG_CONFIG} authorization_letter_url`]:
        moreInfoInputs?.authorization_letter_url,
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
      [`${TRADEMARK} trademark_registration_certificate_url`]:
        moreInfoInputs?.trademark_registration_certificate_url,
    }
  } else if (
    type === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase() &&
    platform === SocialMediaPlatform.YOUTUBE.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${TRADEMARK} holder_name`]: moreInfoInputs?.holder_name,
      [`${TRADEMARK} holder_address`]: moreInfoInputs?.holder_address,
      [`${TRADEMARK} holder_country_code`]: moreInfoInputs?.holder_country_code,
      [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
      [`${TRADEMARK} registration_number`]: moreInfoInputs?.registration_number,
      [`${TRADEMARK} goods_or_services_class`]: moreInfoInputs?.goods_or_services_class,
      [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
      [`${ORG_CONFIG} authorization_letter_url`]:
        moreInfoInputs?.authorization_letter_url,
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
      [`${TRADEMARK} trademark_registration_certificate_url`]:
        moreInfoInputs?.trademark_registration_certificate_url,
    }
  } else if (
    type === ENFORCEMENT_TYPE.PHISHING.toLowerCase() &&
    platform === DomainPlatform.CLOUDFLARE.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
    }
  } else if (
    type === ENFORCEMENT_TYPE.PHISHING.toLowerCase() &&
    platform === DomainPlatform.GODADDY.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
    }
  } else if (
    [
      SocialMediaPlatform.FACEBOOK.toLowerCase(),
      SocialMediaPlatform.INSTAGRAM.toLowerCase(),
    ].includes(platform)
  ) {
    if (type === ENFORCEMENT_TYPE.TRADEMARK) {
      requiredConfigFields = {
        [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
        [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
        [`${ORG_CONFIG} country_code`]: moreInfoInputs?.country_code,
        [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
        [`${TRADEMARK} holder_country_code`]: moreInfoInputs?.holder_country_code,
        [`${TRADEMARK} registration_number`]: moreInfoInputs?.registration_number,
        [`${TRADEMARK} record_link`]: moreInfoInputs?.record_link,
      }
    } else if (type === ENFORCEMENT_TYPE.COPYRIGHT) {
      requiredConfigFields = {
        [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
        [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
        [`${ORG_CONFIG} country_code`]: moreInfoInputs?.country_code,
      }
    }
  } else if (isAutoEmailEnforcementEnabled && productType === ProductType.DOMAIN) {
    requiredConfigFields = {
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
      reporting_agent: moreInfoInputs?.reporting_agent,
      flagged_url: moreInfoInputs?.flagged_url,
      submission_email: submissionEmail,
      [`${BRAND_CONFIG} protected_asset`]:
        moreInfoInputs?.brand_protected_assets?.[0] ??
        moreInfoInputs?.organization_protected_assets?.[0],
    }
    if (type === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase()) {
      requiredConfigFields = {
        ...requiredConfigFields,
        [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
        [`${TRADEMARK} trademark_registration_certificate_url`]:
          moreInfoInputs?.trademark_registration_certificate_url,
      }
    }
  } else if (
    type === ENFORCEMENT_TYPE.PHISHING.toLowerCase() &&
    platform === DomainPlatform.CLEANDNS.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
      [`${BRAND_CONFIG} protected_asset`]:
        moreInfoInputs?.brand_protected_assets?.[0] ??
        moreInfoInputs?.organization_protected_assets?.[0],
    }
  } else if (
    type === ENFORCEMENT_TYPE.TRADEMARK.toLowerCase() &&
    platform === DomainPlatform.CLOUDFLARE.toLowerCase()
  ) {
    requiredConfigFields = {
      [`${ORG_CONFIG} enforcement_email`]: moreInfoInputs?.enforcement_email,
      [`${ORG_CONFIG} legal_name`]: moreInfoInputs?.legal_name,
      [`${TRADEMARK} mark`]: moreInfoInputs?.mark,
      [`${TRADEMARK} registration_number`]: moreInfoInputs?.registration_number,
      [`${TRADEMARK} registration_office`]: moreInfoInputs?.registration_office,
    }
  }

  return requiredConfigFields
}
