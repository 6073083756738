import { withApollo } from '@apollo/react-hoc'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, Heading, IconButton, VStack } from '@chakra-ui/react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { useSelectedReportType } from '../../pages/reports'
import { EnforcementPlatform, ProductType } from '../../utils/constants'
import ExternalEnforcementForm from './external_enforcement_form'
import InternalEnforcementForm from './internal_enforcement_form'
import SpoofDetailImagePreview from '../web2/detail_modal/spoof_detail_image_preview'

type Props = {
  platformOptions: EnforcementPlatform[]
  onCompleted: any
  onClose: any
  useDefaults?: boolean
  productType: ProductType
  returnToTableView?: any
}

function EnforcementModal({
  platformOptions,
  onCompleted,
  onClose,
  productType,
  returnToTableView = () => {},
}: Props) {
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()

  // TODO DOP-2138 Make internal enforcement form work for NFTs
  const form = isEmployeeView ? (
    <InternalEnforcementForm
      onCompleted={onCompleted}
      platformOptions={platformOptions}
      returnToTableView={returnToTableView}
    />
  ) : (
    <ExternalEnforcementForm onCompleted={onCompleted} />
  )

  const headerAndPreview = (
    <>
      <Heading as="h4" fontSize={20} size="sm">
        {`${selectedReportType} Takedown Request Form`}
      </Heading>

      <SpoofDetailImagePreview isCondensedView={true} />
    </>
  )

  return (
    <Flex width={'1000px'}>
      <Flex left="12px" position="absolute" top="12px" zIndex="2">
        <IconButton
          aria-label="back to database"
          borderRadius="500px"
          icon={<ArrowBackIcon />}
          onClick={onClose}
          size={'sm'}
        ></IconButton>
      </Flex>

      <VStack
        align={'left'}
        marginBottom={'50px'}
        marginTop={'70px'}
        mx={'50px'}
        spacing={30}
        width="100%"
      >
        {headerAndPreview}

        {form}
      </VStack>
    </Flex>
  )
}
export default withApollo<Props>(EnforcementModal)
