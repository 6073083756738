import {
  VStack,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { copyTextToClipboard, fallbackCopyTextToClipboard } from '../../utils'
import { DOPPEL_CYBER_BLUE, DOPPEL_INTERNAL_PURPLE } from '../../utils/style'
import DoppelFormLabel from '../shared/forms/doppel_form_label'

export function TemplateSection({ label, template, moreInfoInputs }) {
  const [showTemplate, setShowTemplate] = useState(false)

  if (!template) {
    return null
  }

  // Step 1: Strip pre-existing <span> tags from the template
  const cleanTemplate = template
    .replace(/<span[^>]*>/g, '') // Remove opening <span> tags
    .replace(/<\/span>/g, '') // Remove closing </span> tags
    .replace(/\\n/g, '\n') // Convert `\n` into actual newlines

  // Step 2: If not showing the template, replace placeholders with values if available
  let copyableText = cleanTemplate
  const formatText = (text) => {
    return text.split(/({[^}]+})/g).map((part, index) => {
      const isPlaceholder = part.startsWith('{') && part.endsWith('}')
      const key = part.replace(/[{}]/g, '') // Extract variable name inside `{}`

      if (isPlaceholder) {
        const replacementValue = moreInfoInputs?.[key]
        if (!showTemplate && replacementValue) {
          // If not showing the raw template, replace placeholders with actual values
          copyableText = copyableText.replace(`{${key}}`, replacementValue)
          return (
            <Text as="span" bg={DOPPEL_CYBER_BLUE} key={index} px="1">
              {replacementValue}
            </Text>
          )
        }

        return (
          <Text as="span" bg={DOPPEL_CYBER_BLUE} key={index} px="1">
            {`{${key}}`}
          </Text>
        )
      }

      return (
        <Text as="span" key={index}>
          {part}
        </Text>
      )
    })
  }

  // Step 3: Ensure new lines render correctly
  const linesToRender = cleanTemplate.split('\n')

  return (
    <VStack align="left" width="100%">
      {label && <DoppelFormLabel formLabel={label} />}

      <Flex
        alignItems="center"
        borderColor={DOPPEL_INTERNAL_PURPLE}
        borderRadius="lg"
        borderWidth="2px"
        fontSize="14px"
        justifyContent="space-between"
        padding="8px"
        width="100%"
      >
        <Flex direction="column">
          {linesToRender.map((line, index) => (
            <Text key={index} paddingY="2">
              {formatText(line)}
            </Text>
          ))}

          <Flex justifyContent={'flex-start'} width="100%">
            <Button
              h="1.75rem"
              onClick={() => copyTextToClipboard(copyableText)}
              size="sm"
            >
              Copy
            </Button>

            <Button
              aria-label="Toggle Template"
              h="1.75rem"
              ml={2}
              onClick={() => setShowTemplate(!showTemplate)}
              size="sm"
            >
              {showTemplate ? 'Hide Template' : 'Show Template'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  )
}

export function EnforcementTemplateRow({ label, dbValue, formRegister, formWatch }) {
  const currentValue = formWatch(label) === '' ? null : formWatch(label)

  return (
    <>
      <Text fontSize="12px">
        <b>{label}</b>
      </Text>

      <InputGroup size="md">
        <Input
          alignItems="center"
          borderColor={
            currentValue === undefined || currentValue === dbValue
              ? '#DEDEDE'
              : DOPPEL_CYBER_BLUE
          }
          borderRadius="lg"
          borderWidth="2px"
          defaultValue={dbValue}
          fontSize="14px"
          isDisabled={true}
          justifyContent="space-between"
          padding="8px"
          placeholder={'null'}
          width="100%"
          {...formRegister(label)}
        />

        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            onClick={() => fallbackCopyTextToClipboard(dbValue)}
            size="sm"
          >
            Copy
          </Button>
        </InputRightElement>
      </InputGroup>
    </>
  )
}
