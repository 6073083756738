import { HStack, Flex, Switch, FormControl, Text, Spacer } from '@chakra-ui/react'
import {
  getFlaggedPreviewImage,
  getFlaggedPreviewImageBoxSize,
  WEB2_SCREENSHOTS_BUCKET,
} from '../../../utils/web2'
import { DOPPEL_SCRAPING_BLOCKED } from '@/utils/image'
import ImagePreviewSection from '../../report_detail/image_preview_section'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import SpoofRequestManualScreenshotButton from '../spoof_request_manual_screenshot_button'
import SpoofReportScreenshotVersioningButton from '../spoof_report_screenshot_versioning_button'
import { useEffect, useState } from 'react'
import SpoofDetailUploadScreenshotForm from './spoof_detail_upload_screenshot_form'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import DoppelFormLabel from '@/components/shared/forms/doppel_form_label'
import { ProductType, ReportStatus } from '@/generated/enums'
import { useGetReportMatchesLazyQuery } from '@/generated/graphql'
import { getVersionedScreenshotInfo } from '@/utils/reports/report_utils'
import { useGetGcsObjectLazyQuery } from '@/generated/graphql'
import { extractGCSDetails } from '@/utils/gcs_utils'
import { DOPPEL_ALERT_ICON } from '@/utils/image'

const SpoofDetailImagePreview = ({
  isCondensedView = false,
  showUploadButton = true,
  height = 450,
  width = 450,
}) => {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [isScreenshotUploadFormOpen, setIsScreenshotUploadFormOpen] = useState(false)
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(0)
  const [isEmployeeView] = useIsEmployeeView()
  const [shouldShowDelisted, setShouldShowDelisted] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [shouldShowDelistSwitch, setShouldShowDelistSwitch] = useState(false)
  const [matches, setMatches] = useState([])
  const productType = selectedSpoofReport?.platform?.product
  const isSuspiciousEmails = productType === ProductType.SUSPICIOUS_EMAILS

  const objectFit = isSuspiciousEmails ? 'contain' : 'cover'

  const emailScreenshotGCSDetails = selectedSpoofReport?.spoof_matches?.[0]?.email
    ?.screenshot_url
    ? extractGCSDetails(selectedSpoofReport?.spoof_matches?.[0]?.email?.screenshot_url)
    : null

  const [getEmailScreenshots] = useGetGcsObjectLazyQuery({
    variables: {
      bucket_name: emailScreenshotGCSDetails?.bucketName,
      object_name: emailScreenshotGCSDetails?.objectName,
      include_object: true,
    },
  })
  const [screenshotDate, setScreenshotDate] = useState(null)
  const [screenshotName, setScreenshotName] = useState(null)
  const [updateScreenshots, setUpdateScreenshots] = useState(false)

  const [
    getReportMatchData,
    { data: reportMatchesData, refetch: refetchReportMatchesData },
  ] = useGetReportMatchesLazyQuery({
    variables: {
      report_id: selectedSpoofReport?.id,
    },
  })

  useEffect(() => {
    if (!selectedSpoofReport) {
      setMatches([])
      setShouldShowDelistSwitch(false)
      return
    }

    getReportMatchData({
      variables: {
        report_id: selectedSpoofReport.id,
      },
    })

    setShouldShowDelistSwitch(
      isEmployeeView && selectedSpoofReport?.report_status === ReportStatus.RESOLVED,
    )

    setScreenshotName(
      getVersionedScreenshotInfo(
        { alert: selectedSpoofReport },
        WEB2_SCREENSHOTS_BUCKET,
      )?.name,
    )
  }, [selectedSpoofReport, isEmployeeView])

  useEffect(() => {
    if (isSuspiciousEmails) {
      if (emailScreenshotGCSDetails) {
        getEmailScreenshots().then(({ data }) => {
          const base64Image =
            data?.get_gcs_object?.object_info.object_versions[0]?.object
          setImageUrl(`data:image/png;base64,${base64Image}`)
        })
      } else {
        setImageUrl(DOPPEL_SCRAPING_BLOCKED)
      }
    } else {
      const delistedImage =
        matches.length > 0
          ? `https://storage.googleapis.com/delist_screenshots/${matches[selectedMatchIndex]?.id}_delist`
          : `https://storage.googleapis.com/delist_screenshots/report_${selectedSpoofReport?.id}_delist`
      setImageUrl(
        shouldShowDelisted
          ? delistedImage
          : getFlaggedPreviewImage(selectedSpoofReport),
      )
    }
  }, [shouldShowDelisted, matches, selectedMatchIndex])

  useEffect(() => {
    if (reportMatchesData) {
      setMatches(reportMatchesData.spoof_matches)
    }
  }, [reportMatchesData])

  if (!selectedSpoofReport) return null

  const screenshotVersioningInfo = () => {
    return (
      <Flex width="385px">
        <HStack spacing={2} width="100%">
          <Spacer />

          {screenshotName && (
            <>
              <Text fontSize={12}>{screenshotDate}</Text>

              <SpoofReportScreenshotVersioningButton
                productType={productType}
                screenshotName={screenshotName}
                setImageUrl={setImageUrl}
                setScreenshotDate={setScreenshotDate}
                setUpdateScreenshots={setUpdateScreenshots}
                updateScreenshots={updateScreenshots}
              />
            </>
          )}

          {!isCondensedView && showUploadButton && (
            <SpoofRequestManualScreenshotButton
              isScreenshotUploadFormOpen={isScreenshotUploadFormOpen}
              setIsScreenshotUploadFormOpen={setIsScreenshotUploadFormOpen}
            />
          )}
        </HStack>
      </Flex>
    )
  }

  const renderScreenshotOrUploadForm = () => {
    if (isScreenshotUploadFormOpen) {
      return (
        <SpoofDetailUploadScreenshotForm
          onCompleted={() => {
            setIsScreenshotUploadFormOpen(false)
            refetchReportMatchesData()
          }}
          screenshotName={screenshotName}
          selectedSpoofReport={selectedSpoofReport}
          setImageUrl={setImageUrl}
          setUpdateScreenshots={setUpdateScreenshots}
        />
      )
    }

    return (
      <Flex direction="column" width={width}>
        <ImagePreviewSection
          icon_url={DOPPEL_ALERT_ICON}
          imageUrl={imageUrl}
          image_box_size={getFlaggedPreviewImageBoxSize(selectedSpoofReport)}
          is_flagged={true}
          matchAndIndexSelector={{
            spoofMatches: matches ?? [],
            selectedMatchIndex,
            setSelectedMatchIndex,
          }}
          objectFit={objectFit}
          setShouldShowDelisted={setShouldShowDelisted}
          shouldShowDelisted={shouldShowDelisted}
          shouldShowMatchScreenshots={isEmployeeView}
          subtitle="Matched Entity"
          title={selectedSpoofReport.unsanitized_url || selectedSpoofReport.flagged_url}
        />

        {screenshotVersioningInfo()}

        {shouldShowDelistSwitch && (
          <Flex width={width}>
            <FormControl>
              <HStack>
                <Switch
                  isChecked={shouldShowDelisted}
                  onChange={() => {
                    setShouldShowDelisted(!shouldShowDelisted)
                  }}
                  size="sm"
                />

                <DoppelFormLabel
                  fontSize="xs"
                  formLabel={
                    shouldShowDelisted ? 'Taken Down Screenshot' : 'Original Screenshot'
                  }
                  helperText="Switch between the original screenshot and the screenshot of taken down content if available."
                />
              </HStack>
            </FormControl>
          </Flex>
        )}
      </Flex>
    )
  }

  const renderImagePreview = () => {
    return (
      <Flex height={shouldShowDelistSwitch ? height + 10 : height} width={width}>
        {renderScreenshotOrUploadForm()}
      </Flex>
    )
  }

  return isCondensedView ? (
    <HStack>{renderImagePreview()}</HStack>
  ) : (
    <>{renderImagePreview()}</>
  )
}

export default SpoofDetailImagePreview
